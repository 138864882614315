define([
  'jquery',
  'application',
  'backbone',

  'modules/common/components/component',
  'modules/common/crontabs/cron',
  'modules/upx/acls/loggedIn',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/components/timer',

  'modules/shop.cash-register-retail/collections/upx/OnlineFoodOrderProvider',
  'modules/shop.cash-register-retail/collections/upx/OnlineFoodOrder',

  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/common/components/currency',
  'modules/common/components/promisify',
  'modules/shop.cash-register-retail/components/toaster',
  'howler',
], (
  $, App, Backbone,
  Component, Cron, LoggedInAcl, Upx, Timer,
  OnlineFoodOrderProvider, OnlineFoodOrderCollection,
  Locale, Moment, Currency, Promisify, Toaster, { Howl },
) => {
  const STATUS_NEW = 'new';
  const STATUS_CONFIRMED = 'confirmed';
  const STATUS_IN_KITCHEN = 'in-kitchen';
  const STATUS_FINISHED = 'finished';
  const STATUS_CANCELED = 'canceled';

  const ALL_STATUSES = [
    STATUS_NEW, STATUS_CONFIRMED, STATUS_IN_KITCHEN, STATUS_FINISHED, STATUS_CANCELED,
  ];
  const c = Component.extend({
    STATUS_NEW,
    STATUS_CONFIRMED,
    STATUS_IN_KITCHEN,
    STATUS_FINISHED,
    STATUS_CANCELED,
    ALL_STATUSES,

    initialize() {
      const self = this;
      const CronClass = Cron.extend({
        cron: '*/15 * * * * *',
        run() {
          self.runCron();
        },
      });
      this.cron = new CronClass();
      this.cron.start();

      this.sound = new Howl({
        src: ['./newOnlineOrder.webm'],
        pool: 1,
      });
      this.loadTestAudio();
    },

    loadTestAudio() {
      const testAudioElement = new Audio('./silence.webm');
      testAudioElement.addEventListener('loadeddata', () => {
        this.testAudioElement = testAudioElement;
      });
      testAudioElement.addEventListener('error', (e) => {
        console.error('Failed to load audio for testing', e);
      });
      testAudioElement.load();
    },

    async testSound() {
      if (!this.testAudioElement) {
        console.error('Test audio element not loaded');
        // we still return true, maybe normal audio will work
      } else {
        try {
          await this.testAudioElement.play();
        } catch (e) {
          if (e instanceof DOMException) {
            return false; // failed to play
          }
          throw e;
        }
      }
      return true;
    },

    isEnabled() {
      return (new LoggedInAcl()).authorized() && this.isOnlineOrderEnabled();
    },

    playSound() {
      this.sound.play();
    },

    async soundCron() {
      if (!this.isEnabled()) {
        return;
      }

      if (OnlineFoodOrderCollection.getNewOrderCount() > 0) {
        if (await this.testSound()) {
          this.playSound();
        } else {
          Toaster.warning(Locale.translate('audio_is_muted_touch_the_screen_to_unmute'));
        }
      }
    },

    async runCron() {
      if (!this.isEnabled()) {
        return;
      }

      const timer = Timer.createTimer('SYNC_ONLINE_FOOD_ORDER', {
        initialSize: OnlineFoodOrderCollection.length,
      });
      try {
        const shopIds = OnlineFoodOrderProvider.pluck('shop_id');
        await Promisify.deferredToPromise(Upx.call('OnlineFoodOrderModule', 'syncOrderProviders',
          {
            filters: [{
              name: 'shop_id__in_list',
              multi_val: shopIds,
            }],
          }));
        await OnlineFoodOrderCollection.loadPromise();
        timer.resolve({
          afterSize: OnlineFoodOrderCollection.length,
          shopIds,
        });
      } catch (e) {
        timer.reject(e);
        console.warn('Failed to get the online orders', e);
        Toaster.error(Locale.translate('failed_to_get_online_orders'));
      }

      await this.soundCron();
    },

    isOnlineOrderEnabled() {
      return OnlineFoodOrderProvider.length > 0;
    },
    getNewOrderCount() {
      if (!this.isOnlineOrderEnabled()) {
        return 0;
      }
      return OnlineFoodOrderCollection.getNewOrderCount();
    },

    async syncByShopOrderId(orderId) {
      if (!this.isEnabled()) {
        return null;
      }

      const model = OnlineFoodOrderCollection.findWhere({ shop_order_id: orderId });
      if (model) {
        await Promisify.deferredToPromise(model.fetch());
        return model;
      }
      return null;
    },
    getPaymentMethodName(method) {
      if (method === 'cash') {
        return Locale.translate('cash');
      }
      if (method === 'online') {
        return Locale.translate('online_payment');
      }
      if (method === 'pin at doorstep') {
        return Locale.translate('pin_at_doorstep');
      }
      if (method === 'on account') {
        return Locale.translate('on_account');
      }
      if (method === 'creditcard') {
        return Locale.translate('credit_card_payment');
      }
      console.error('Unknown payment method', method);
      return Locale.translate('other');
    },

    getPlatformName(platformAlias) {
      if (platformAlias === 'sitedish') {
        return 'Sitedish';
      } if (platformAlias === 'pos') {
        return 'Sitedish POS';
      } if (platformAlias === 'thuisbezorgd') {
        return 'Thuisbezorgd.nl';
      } if (platformAlias === 'ubereats') {
        return 'Uber Eats';
      } if (platformAlias === 'deliveroo') {
        return 'Deliveroo';
      }
      return 'Unknown';
    },
    getSitedishDataFromModel(model) {
      const phoneNumber = model.get('external_order.customer.phoneNumber');
      const number = model.get('external_order.orderNumber');
      const isCanceled = model.get('status') === STATUS_CANCELED;
      const isPickup = model.get('external_order.orderType') === 'takeaway';
      const datetimeOrdered = model.get('external_order.orderDate');
      const timeRequested = model.get('external_order.requestedAsap')
        ? Locale.translate('asap')
        : Moment(model.get('external_order.requestedTime')).format('HH:mm');
      const timeConfirmed = model.get('external_order.confirmedTime')
        ? Moment(model.get('external_order.confirmedTime')).format('HH:mm')
        : timeRequested;
      let customerName = model.get('external_order.customer.name');
      if (model.get('external_order.customer.companyName')) {
        customerName += ` (${model.get('external_order.customer.companyName')})`;
      }
      const distance = model.get('external_order.location')
        ? (model.get('external_order.location.distance') / 1000.0).toFixed(2)
        : 0;

      const addressLine1 = (`${model.get('external_order.customer.street')} ${
        model.get('external_order.customer.streetNumber')} ${model.get('external_order.customer.extraAddressInfo')}`).trim();
      const addressLine2 = (`${model.get('external_order.customer.postalCode')} ${
        model.get('external_order.customer.city')}`).trim();
      const totalPrice = Currency.format('EUR', model.get('external_order.totalPrice'));
      let totalItems = 0;
      const notes = model.get('external_order.remark');

      const items = [];
      (model.get('external_order.products') || []).forEach(
        (item) => {
          totalItems += item.count || 1;
          const outItem = {
            name: item.name,
            category: item.category,
            quantity: item.count,
            description: item.instructions,
            subtotalWt: Currency.format('EUR', item.subtotal),
            sub_items: [],
          };
          if (item.sideDishes) {
            item.sideDishes.forEach((sideDish) => {
              outItem.sub_items.push({
                name: sideDish.name,
                quantity: sideDish.count,
              });
            });
          }
          items.push(outItem);
        },
      );

      const discounts = [];
      (model.get('external_order.discounts') || []).forEach(
        (item) => {
          const outItem = {
            name: item.name,
            priceWt: Currency.format('EUR', item.price),
          };
          discounts.push(outItem);
        },
      );
      let deliveryCosts = 0;
      if (model.get('external_order.deliveryCosts')) {
        deliveryCosts = Currency.format('EUR', model.get('external_order.deliveryCosts'));
      }

      const isPaid = model.get('is_paid') || model.get('shop_order.is_paid');
      const paymentMethod = model.get('external_order.paymentMethod');
      const paymentMethodName = this.getPaymentMethodName(paymentMethod);
      let paymentMethodDetails = '';
      if (paymentMethod === 'cash' && !isPickup) {
        if (model.get('external_order.paysExact')) {
          paymentMethodDetails = Locale.translate('customer_pays_exact_{total_price}_amount', { total_price: totalPrice });
        } else {
          const pays_with = Currency.format('EUR', model.get('external_order.paysWith'));
          paymentMethodDetails = Locale.translate('customer_pays_with_{pays_with}', { pays_with });
        }
      }
      const platformAlias = model.get('external_order.platform');
      const platform = this.getPlatformName(platformAlias);
      return {
        platform,
        paymentMethod,
        paymentMethodName,
        paymentMethodDetails,
        isPaid,
        deliveryCosts,
        datetimeOrdered,
        discounts,
        isCanceled,
        notes,
        number,
        phoneNumber,
        isPickup,
        customerName,
        timeRequested,
        timeConfirmed,
        distance,
        addressLine1,
        addressLine2,
        totalPrice,
        totalItems,
        items,

        // for translations
        time_confirmed: timeConfirmed,
        time_requested: timeRequested,
      };
    },
  });
  return new c();
});
