define([
  'jquery',
  'underscore',
  'modules/common/components/component',
  'modules/common/components/idle',
  'modules/common/components/moment',
  'modules/shop.cash-register-retail/models/pinKey',
  'modules/shop.common/components/mode',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/models/connection',

], ($, _, Component, Idle, Moment, PinKeyModel, ModeComponent, DeviceConfig, CashRegisterApi, ConnectionModel) => {
  const Info = Component.extend({

    testSentry() {
      this._consoleError(`UpxInfo.testSentry on ${new Date()}`);
    },

    _consoleError(message) {
      console.error(message);
    },

    info() {
      const data = {
        lastIdleAgo: new Moment(Idle.lastActive).fromNow(),
        lastIdle: new Moment(Idle.lastActive).format(),
        now: new Moment().format(),
        pinIsEnabled: PinKeyModel.isEnabled(),
        isInWebOrAppMode: ModeComponent.isInWebMode() || ModeComponent.isInAppMode(),
        deviceUuid: DeviceConfig.getDeviceUUID(),
        deviceName: DeviceConfig.getDeviceName(),
        localStorageSize: this.getLocalStorageSize(),
        localStorageLength: localStorage.length,
      };

      DeviceConfig.collection.each((model) => {
        data[`config: ${model.get('alias')}`] = {
          extra: model.get('extra'),
          date_updated: model.get('date_updated'),
        };
      });

      return data;
    },

    getLocalStorageSize() {
      let allStrings = '';
      for (const key in window.localStorage) {
        if (window.localStorage.hasOwnProperty(key)) {
          allStrings += window.localStorage[key] + key;
        }
      }
      return allStrings ? `${3 + ((allStrings.length * 16) / (8 * 1024))} KB` : 'Empty (0 KB)';
    },

    printTable(info) {
      $.each(info, (k, v) => {
        if (typeof v === 'object') {
          info[k] = JSON.stringify(v);
        }
      });
      console.table(info);
    },

    printNetworkInfo() {
      ConnectionModel.getConnectionStatistics().then((stats) => {
        console.table(stats);
      });
    },

    printInfo() {
      const info = this.info();
      console.log('Software information:');
      this.printTable(info);

      CashRegisterApi.call('/device-information', 'get')
        .then((data) => {
          console.log('Device information:');
          this.printTable(data);
        }, (response) => {
          console.log('Device information:');
          console.error(response.error);
        });

      this.printNetworkInfo();
    },
  });

  const obj = new Info();
  window.UpxInfo = obj;
  return obj;
});
