define([
  'jquery',
  'underscore',
  'backbone',

  'upx.modules/ShopModule/models/MemberCard',
  'upx.modules/ShopModule/collections/MemberCard',
  'upx.modules/RelationsModule/collections/FullInfoRelation',
  'upx.modules/RelationsModule/models/FullInfoRelation',

  'modules/shop.cash-register-retail/collections/upx/DefaultShopConfigurationAttachedGroup',

  'modules/shop.cash-register-retail/views/popups/customersPopup',
  'modules/shop.cash-register-retail/views/popups/memberCardListPopup',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/common/components/locale',
], (
  $, _, Backbone,
  MemberCardModel, MemberCardCollection, FullInfoRelationCollection, FullInfoRelationModel,
  DefaultShopConfigurationAttachedGroupCollection,
  CustomersPopup, MemberCardListPopup, MessagePopup,
  Locale,
) => ({

  /**
         * Searched for a customer card based on the number, it it can't find any,
         * it will search a customer with that number.
         * @param number
         * @return {jQuery.Deferred|{}}
         */
  searchMember(number) {
    const self = this;
    const def = new $.Deferred();

    // Search for customer card  first then customer
    const int = parseInt(number);
    if (_.isNumber(int) && !_.isNaN(int)) {
      const memberCardModel = new MemberCardModel();
      memberCardModel.find({ code: number })
        .then((response) => {
          const cardModel = new MemberCardModel(response);
          const relationModel = new FullInfoRelationModel(cardModel.get('relation_data'));
          relationModel.set('active_membercard', cardModel.get('active'));
          relationModel.set('notes_membercard', cardModel.get('notes'));
          relationModel.set('code_membercard', cardModel.get('code'));
          relationModel.set('due_date_membercard', cardModel.get('due_date_inclusive'));
          def.resolve(relationModel);
        }, () => {
          self._searchCustomer(number)
            .then((response) => {
              self._handleCustomerSearchFn(response, def);
            }, def.reject);
        });
    }
    // Search for customer
    else {
      self._searchCustomer(number)
        .then((response) => {
          self._handleCustomerSearchFn(response, def);
        }, def.reject);
    }

    return def;
  },

  _findCustomersMemberCard(relationModel) {
    const self = this;
    const def = new $.Deferred();

    // Fetching all customer cards below a user,
    // If there is 1: apply that one.
    // If there are multiple, Show a popup with all them.
    // Else show none.
    const memberCardCollection = new MemberCardCollection();
    const params = {
      params: {
        start: 0,
        limit: 0,
        sort: [{
          name: 'due_date_inclusive',
          dir: 'desc',
        }],
        filters: [{
          name: 'relation_data_id__=',
          val: relationModel.get('id'),
        }, {
          name: 'active__=',
          val: '1',
        }],
      },
    };

    memberCardCollection.fetch(params)
      .then(() => {
        // Adds the customer card details when there is one.
        if (memberCardCollection.length === 1) {
          const cardModel = memberCardCollection.first();
          relationModel.set('active_membercard', cardModel.get('active'));
          relationModel.set('notes_membercard', cardModel.get('notes'));
          relationModel.set('code_membercard', cardModel.get('code'));
          relationModel.set('due_date_membercard', cardModel.get('due_date_inclusive'));
          def.resolve();
        } else

        if (memberCardCollection.length > 1) {
          const viewDef = new $.Deferred();
          // Waits on the view to resolve with a customerModel.
          viewDef.then((cardModel) => {
            relationModel.set('active_membercard', cardModel.get('active'));
            relationModel.set('notes_membercard', cardModel.get('notes'));
            relationModel.set('code_membercard', cardModel.get('code'));
            relationModel.set('due_date_membercard', cardModel.get('due_date_inclusive'));
            def.resolve();
          }, () => {
            def.resolve();
          });

          const view = new MemberCardListPopup();
          view.open(
            memberCardCollection,
            relationModel,
            viewDef,
          );
        } else {
          def.resolve();
        }
      }, def.reject);

    return def;
  },

  _handleCustomerSearchFn(response, def) {
    const self = this;
    // Check if it has found one customer.
    if (response.total === 1 && 'model' in response) {
      const { model } = response;
      this._findCustomersMemberCard(model)
        .then(() => {
          def.resolve(model);
        }, def.reject);
    } else if (response.total > 1 && 'collection' in response) {
      // Check if it has found multiple customers.

      const viewDef = new $.Deferred();
      // Waits on the view to resolve with a customerModel.
      viewDef.then((customerModel) => {
        self._findCustomersMemberCard(customerModel)
          .then(() => {
            def.resolve(customerModel);
          }, def.reject);
      }, () => {
        def.resolve(null);
      });

      var view = new CustomersPopup();
      view.open(
        response.collection,
        viewDef,
      );
    } else {
      def.reject();

      var view = new MessagePopup();
      view.open(Locale.translate('no_customer_found'));
    }
  },

  _searchCustomer(query) {
    const def = new $.Deferred();

    const fullInfoRelationCollection = new FullInfoRelationCollection();
    const params = {
      params: {
        start: 0,
        limit: 25,
        filters: [{
          name: 'search__word',
          val: query,
        }, {
          name: 'group_id__direct_member_in_list',
          val: DefaultShopConfigurationAttachedGroupCollection.getIdByAlias(DefaultShopConfigurationAttachedGroupCollection.CUSTOMER_ALIAS),
        }],
      },
    };

    fullInfoRelationCollection.fetch(params)
      .then((response) => {
        const { total } = response;
        const data = { total };

        if (total === 1) {
          data.model = fullInfoRelationCollection.first();
        } else if (total > 1) {
          data.collection = fullInfoRelationCollection;
        }

        def.resolve(data);
      }, def.reject);

    return def;
  },

}));
