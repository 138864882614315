define([
  'application',
  'jquery',
  'modules/common/components/component',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/collections/orderMemory',

  'modules/shop.cash-register-retail/models/selectedCustomer',
  'upx.modules/PaymentModule/models/GiftCard',
  'upx.modules/ShopModule/models/MemberCard',
], (App, $, Component,
  OrderItemCollection, DefaultShopConfigurationModel, OrderMemoryCollection,
  SelectedCustomer, GiftCardModel, MemberCardModel) => {
  const Menu = Component.extend({

    /**
         * stores current order if it makes sense
         * @returns {boolean}
         */
    storeCurrent() {
      const count = OrderItemCollection.getTotalItems();
      if (count) {
        const order = {
          id: new Date().getTime(),
          currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
          order_items: OrderItemCollection.toJSON(),
          item_no: count,
          value_wt: OrderItemCollection.getTotalPriceWt(),
          customer: SelectedCustomer.toJSON(),
        };
        OrderMemoryCollection.add(order).save();
        return true;
      }
      return false;
    },

    replaceOrderItemsInCheckout(newOrderItems) {
      const itemDefers = [];

      newOrderItems = newOrderItems || [];
      OrderItemCollection.clear(); // clear items
      newOrderItems.forEach((item) => {
        const defGiftCard = new $.Deferred();
        const defMemberCard = new $.Deferred();

        if (item.type === OrderItemCollection.TYPE_GIFTCARD
                    || item.type === OrderItemCollection.TYPE_MEMBERCARD_GIFTCARD) {
          const card = new GiftCardModel();
          const def = card.find({ code: item.code });
          OrderItemCollection.addLoaderByDef(def);
          // resolve if not found
          def.then(defGiftCard.reject, defGiftCard.resolve);
        } else {
          // OrderItemCollection.TYPE_TOP_UP <- this one is always ok
          defGiftCard.resolve();
        }

        if (item.type === OrderItemCollection.TYPE_MEMBERCARD
                    || item.type === OrderItemCollection.TYPE_MEMBERCARD_GIFTCARD) {
          const card = new MemberCardModel();
          const def = card.find({ code: item.code });
          OrderItemCollection.addLoaderByDef(def);
          // resolve if not found
          def.then(defMemberCard.reject, defMemberCard.resolve);
        } else {
          defMemberCard.resolve();
        }

        const itemDeffer = new $.Deferred();
        itemDefers.push(itemDeffer);
        $.when(defGiftCard, defMemberCard).then(
          () => {
            OrderItemCollection.add(item).save();
            itemDeffer.resolve();
          },
          itemDeffer.reject,
        );
      });

      return $.when(...itemDefers);
    },
    /**
         * stores current order if it makes sense
         * @returns {boolean}
         */
    restoreCurrent(model) {
      this.replaceOrderItemsInCheckout(model.get('order_items'));

      const customer = model.get('customer');
      if (customer && typeof customer === 'object' && customer.id ) {
        SelectedCustomer.clear();
        SelectedCustomer.set(customer).save();
      } else {
        SelectedCustomer.unload();
      }
    },

  });

  return new Menu();
});
