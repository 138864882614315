define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',

  'upx.modules/ManagementModule/models/Subscription',
  'upx.modules/ManagementModule/collections/Feature',
], (
  $, _, Backbone, Component,
  SubscriptionModel, FeatureCollection,
) => {
  const subscriptionModuleName = 'CommerceModule';
  const subscriptionAlias = 'StoreKeeper';

  const subscriptionInit = {
    module_name: subscriptionModuleName,
    alias: subscriptionAlias,
  };

  const Feature = Component.extend({

    /**
     * Constants
     */
    APP_NAME_PRODUCTS_MODULE_PRODUCT_STOCK_SERIAL: 'core.ProductsModule.ProductStockSerial',
    APP_NAME_CORE_SHOP_MODULE_REPAIRS: 'core.ShopModule.Repairs',
    APP_NAME_CORE_BILLING_MODULE_INVOICING: 'core.BillingModule.Invoicing',
    APP_NAME_CORE_EMAIL_MODULE_EMAIL: 'core.EmailModule.Email',
    APP_CORE_SHOP_MODULE_BACKOFFICE_ORDER_PROCESSING: 'core.ShopModule.BackofficeOrderProcessing',
    APP_NAME_CORE_SHOP_MODULE_LOYALTY_PROGRAM: 'core.ShopModule.LoyaltyProgram',

    /**
		 * Initialize functions
		 */

    initialize() {
      this.subscription = new SubscriptionModel(subscriptionInit);
      this.featureCollection = new FeatureCollection();
    },

    /**
		 * Load functions
		 */

    loadDeferred: false,

    isLoaded() {
      return this.loadDeferred && this.loadDeferred.state() === 'resolved';
    },
    isLoading() {
      return this.loadDeferred && this.loadDeferred.state() === 'pending';
    },

    load(force) {
      if (this.isLoading()) {
        return this.loadDeferred;
      }
      const def = new $.Deferred();
      def.fail(() => this.unload());

      if (force || !this.isLoaded()) {
        this.unload();

        this.subscription.getDetailsByAlias(_.extend({}, {
          feature_filters: [{
            name: 'name__in_list',
            multi_val: this.getAllFeatureUsedNames(),
          }],
          ...subscriptionInit,
        })).then(
          (data) => {
            const features = data.features || [];
            this.featureCollection.add(features);
            delete data.features;
            this.subscription.set(data);

            def.resolve();
          },
          def.reject,
        );
      } else {
        def.resolve();
      }
      this.loadDeferred = def.promise();
      return this.loadDeferred;
    },

    unload() {
      this.featureCollection.reset();
      this.subscription.clear({ silent: true });
      this.subscription.set(subscriptionInit);
      this.loaded = false;
    },

    checkLoaded() {
      if (!this.isLoaded()) {
        throw new Error('Subscription data is not loaded.');
      }
    },

    getFeatureByAppName(name) {
      this.checkLoaded();
      return this.featureCollection.findWhere({ 'feature_type.name': name });
    },

    getFeatureActiveByAppName(name) {
      this.checkLoaded();
      const feature = this.getFeatureByAppName(name);
      if (feature) {
        return feature.get('active');
      }
      return false;
    },

    isProductSerialFeatureEnabled() {
      return this.getFeatureActiveByAppName(this.APP_NAME_PRODUCTS_MODULE_PRODUCT_STOCK_SERIAL);
    },

    getAllFeatureUsedNames() {
      return [
        this.APP_NAME_PRODUCTS_MODULE_PRODUCT_STOCK_SERIAL,
        this.APP_NAME_CORE_SHOP_MODULE_REPAIRS,
        this.APP_NAME_CORE_BILLING_MODULE_INVOICING,
        this.APP_NAME_CORE_EMAIL_MODULE_EMAIL,
        this.APP_CORE_SHOP_MODULE_BACKOFFICE_ORDER_PROCESSING,
        this.APP_NAME_CORE_SHOP_MODULE_LOYALTY_PROGRAM,
      ];
    },

  });

  return new Feature();
});
