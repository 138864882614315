define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',

  'modules/shop.cash-register-retail/components/backgroudSync',
  'modules/shop.cash-register-retail/components/payment',
  'modules/common/components/promisify',

  'modules/shop.cash-register-retail/models/upx/Order',
  'upx.modules/PaymentModule/models/Payment',
  'upx.modules/PaymentModule/models/GiftCardPayment',
  'upx.modules/ShopModule/models/OrderInvoice',

  'upx.modules/ShopModule/models/MemberCard',
  'upx.modules/ShopModule/models/GiftCardOnOrderItem',
  'modules/shop.cash-register-retail/collections/paymentResults',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/models/giftCardPaymentProvider',

  'modules/shop.cash-register-retail/models/settings/pointOfSale',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/models/settings/terminal',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'modules/shop.cash-register-retail/models/selectedCustomer',

  'modules/shop.cash-register-retail/collections/upx/PaymentProviderMethod',
  'modules/shop.cash-register-retail/collections/upx/PaymentProvider',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'upx.modules/ShopModule/collections/Order',
  'modules/shop.cash-register-retail/models/settings/pointOfSale',

  'modules/common/collections/DeepModelCollection',
  'modules/common/components/connection',
  'modules/shop.cash-register-retail/models/paymentMethodItem',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/components/productionGroupPrint',

  'upx.modules/ShopModule/models/OrderShipment',
  'upx.modules/ShippingModule/collections/ShipmentStatusType',
  'upx.modules/ShippingModule/models/Shipment',
  'upx.modules/ShippingModule/collections/ShippingMethod',

  'modules/common/components/currency',
  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/collections/lastOrders',
  'upx.modules/ShopModule/models/OrderLog',
  'modules/shop.cash-register-retail/models/settings/shopPos',
  'modules/shop.common/components/deviceConfig',

  'modules/shop.cash-register-retail/components/receiptSigning.js',
  'modules/shop.cash-register-retail/components/toaster',
], (
  $, _, Backbone, Component,
  BGSync, Payment, Promisify,
  OrderModel, PaymentModel, GiftCardPaymentModel, OrderInvoiceModel,
  MembercardModel, GiftCardOnOrderItemModel, PaymentResultsCollection,
  DefaultShopConfigurationModel, GiftCardPaymentProvider,
  PointOfSaleSetting, ReceiptPrinterModel, TerminalSetting,
  CurrentOrderItemCollection, PaymentMethodItemCollection, SelectedCustomerModel,
  PaymentProviderMethodCollection, PaymentProviderCollection, CashRegisterApi, OrderCollection, pointOfSaleSetting,
  DeepModelCollection, ConnectionComponent, PaymentMethodItemModel, PaymentMethodsSettingModel, ProductionGroupPrint,
  OrderShipmentModel, ShipmentStatusTypeCollection, ShipmentModel, ShippingMethodCollection,
  Currency, Locale, Moment, Upx, LastOrdersCollection, OrderLogModel, ShopPosModel, DeviceConfig,
  ReceiptSigning, Toaster,
) => {
  const OrderComponent = Component.extend({

    JOB_SAVE_RECEIPT: 'order::save_receipt',
    JOB_ATTACH_PAYMENTS: 'order::attach_payments',
    JOB_MARK_SHIPPED: 'order::mark_shipped',
    JOB_MARK_PARTIAL_SHIPPED: 'order::mark_partial_shipped',

    ERROR_INVALID_ORDER_PRODUCTS: 'ShopModule::InvalidOrderProducts',

    initialize(option) {
      Component.prototype.initialize.apply(this, option);

      BGSync.registerJobType(this.JOB_SAVE_RECEIPT, this.saveOrderReceipt, this);
      BGSync.registerJobType(this.JOB_MARK_SHIPPED, this.markAsShipped, this);
      BGSync.registerJobType(this.JOB_MARK_PARTIAL_SHIPPED, this.executeDeliveryBackgroundTask, this);
      // Deprecated job, But not removed because of existing jobs.
      BGSync.registerJobType(this.JOB_ATTACH_PAYMENTS, this.attachPaymentIds, this);
    },

    async saveOrderReceiptJob(orderId, ReceiptEscpos) {
      return BGSync.addJob(
        this.JOB_SAVE_RECEIPT,
        [this.getOrderBackRef(orderId)],
        {
          orderId,
          ReceiptEscpos,
        },
      );
    },

    saveOrderReceipt(args) {
      return BGSync.awaitDeferred(Upx.call('ShopModule', 'setOrderVars', {
        fields: {
          order_id: args.orderId,
          order_vars: [{
            alias: 'receipt_escpos',
            value: args.ReceiptEscpos,
          }],
        },
      }));
    },

    getOrderBackRef(orderId) {
      return `ShopModule::Order(id=${orderId})`;
    },

    async isOrderSynchronizingToBackend(orderId) {
      return BGSync.hasJobsForObject(this.getOrderBackRef(orderId));
    },

    async runOrderSync(orderId) {
      const jobs = await BGSync.getJobsForObject(this.getOrderBackRef(orderId));
      const results = [];
      for (let i = 0; i < jobs.length; i++) {
        const job = jobs[i];
        try {
          await BGSync.runJob(job);
          results.push({
            job,
            success: true,
          });
        } catch (e) {
          results.push({
            job,
            success: false,
            error: e,
          });
        }
      }
      return results;
    },

    saveToLocalStorage() {
      PaymentMethodItemCollection.each(
        (model) => model.save(),
      );
      CurrentOrderItemCollection.each(
        (model) => model.save(),
      );
    },

    newOrderLogComment(orderId, title, comment) {
      const logModel = new OrderLogModel({
        order_id: orderId,
        title,
        comment,
      });
      const def = logModel.save();
      def.fail((error) => {
        CashRegisterApi.logAction('ORDER_LOG_FAILED', {
          logModel: logModel.toJSON(),
          error,
        });
      });
      return def.promise();
    },

    isTotalCorrectWithRemote(order) {
      const order_value_wt = Currency.toCurrency(order.get('value_wt'));
      const local_order_value_wt = CurrentOrderItemCollection.getTotalPriceWt();
      return Currency.Math.isEqual(order_value_wt, local_order_value_wt);
    },

    processOrderSignature(orderModel, processingView, paymentResults) {
      const def = new $.Deferred();

      Promisify.promiseToDeferred(
        ReceiptSigning.signOrder(
          orderModel, processingView, paymentResults.getPaymentIds(), PaymentMethodItemCollection,
        ),
      ).then(
        (data) => {
          orderModel.set({ transaction_signature: data });

          def.resolve();
        },
        () => {
          orderModel.set({
            transaction_signature: {
              error: Locale.translate('failed_to_generate_receipt_signature'),
            },
          });
          // always resolve, it's ok, because we print that it failed on the receipt
          def.resolve();
        },
      );

      return def.promise();
    },

    processOrder(orderModel, processingView, cashierDisplay, orderItemCollection) {
      orderItemCollection = orderItemCollection || CurrentOrderItemCollection;
      const def = new $.Deferred();
      Promisify.promiseToDeferred(this.processPayments({
        orderId: orderModel.get('id'),
        orderModel,
        processingView,
        cashierDisplay,
        totalValueWt: orderItemCollection.getTotalPriceWt(),
        paymentMethodCollection: PaymentMethodItemCollection,
        paymentProducts: Payment.getPaymentProducts(orderItemCollection, true),
      }))
        .then(
          (paymentResults) => {
            this.processShipment(orderModel, processingView)
              .then(
                () => {
                  this.processOrderSignature(orderModel, processingView, paymentResults)
                    .always(() => def.resolve(paymentResults));
                },
                (shipError) => {
                  // not sure how to recover, probably some manual action is required
                  console.error('ERROR on shipping', shipError);

                  Payment.refundPayments(paymentResults).always(
                    ({ refundIds }) => {
                      try {
                        if (refundIds.length) {
                          Payment.scheduleAttachPaymentsToOrder({
                            orderId: orderModel.get('id'),
                            paymentIds: refundIds,
                          });
                        }
                        Payment.lockSuccessfulPaymentMethods({
                          paymentResults,
                          paymentMethodCollection: PaymentMethodItemCollection,
                        });
                      } catch (e) {
                        console.error('ERROR refunding', e);
                      }
                      def.reject(_.extend({}, paymentResults, shipError, refundIds));
                    },
                  );
                },
              );
          },
          def.reject,
        );
      return def.promise();
    },

    async processPayments({
      orderId,
      orderModel,
      processingView,
      cashierDisplay,
      totalValueWt,
      paymentMethodCollection,
      paymentProducts,
    }) {
      let paymentResults;
      try {
        let relation_data_id = null;
        if (orderModel && !orderModel.get('is_anonymous')) {
          relation_data_id = orderModel.get('relation_data_id');
        }
        paymentResults = await Payment.processPaymentMethods({
          orderModel,
          totalValueWt,
          processingView,
          cashierDisplay,
          paymentMethodCollection,
          paymentProducts,
          relation_data_id,
        });

        await Payment.attachOrderPayments({
          paymentResults,
          processingView,
          orderId,
        });
      } catch (error) {
        Payment.processFailedOrderPayments({
          error,
          orderId,
          processingView,
          number: orderModel.get('number'),
          paymentMethodCollection,
        });

        throw error;
      }
      return paymentResults;
    },

    processShipment(orderModel, processingView) {
      const shipmentCalls = [
        () => this.createGiftcards(orderModel, processingView),
        () => this.createMembership(orderModel, processingView),
      ];
      if (
        !orderModel.get('table_id')
        && ShopPosModel.get('mode') === DeviceConfig.MODE_Hospitality
      ) {
        // direct order, should print the production items
        shipmentCalls.push(
          () => this.processProductionPrintout(orderModel, processingView),
        );
      }

      // marking as shipped should be after delivery otherwise it interferes with giftcards
      shipmentCalls.push(
        () => this.processDelivery(orderModel, processingView),
      );
      return Upx.eachCall(shipmentCalls)
        .promise();
    },

    processProductionPrintout(orderModel, processingView) {
      const orderItemCollection = new DeepModelCollection(orderModel.get('order_items'));

      // gather product ids
      const productIds = [];
      orderItemCollection.each((item) => {
        if (item.get('product_id')) {
          productIds.push(item.get('product_id'));
        }
        if (item.get('subitems')) {
          const subItems = item.get('subitems') || [];
          for (let i = 0; i < subItems.length; i++) {
            const subItem = subItems[i];
            if (subItem.product_id) {
              productIds.push(subItem.product_id);
            }
          }
        }
      });
      const def = new $.Deferred();
      Upx.call('ShopModule', 'getProductionGroupIdsForProductIds',
        {
          product_ids: productIds,
        })
        .then(
          (data) => {
            if (data.count && data.data.length) {
              try {
                // there are production groups to print
                const log = processingView.printing();
                log.setProcessingStatus();

                // apply production groups to items and subitems
                const groupsByProductId = {};
                data.data.map(({ production_group_id, product_id }) => {
                  groupsByProductId[product_id] = production_group_id;
                });
                orderItemCollection.each((item) => {
                  if (item.get('product_id') in groupsByProductId) {
                    item.set('production_group_id', groupsByProductId[item.get('product_id')]);
                  }
                  if (item.get('subitems')) {
                    const subItems = item.get('subitems') || [];
                    for (let i = 0; i < subItems.length; i++) {
                      const subItem = subItems[i];
                      if (subItem.product_id in groupsByProductId) {
                        subItem.production_group_id = groupsByProductId[subItem.product_id];
                      }
                    }
                    item.set('subitems', subItems);
                  }
                });

                // start the printing
                (async () => {
                  try {
                    await ProductionGroupPrint.printDirectOrder(
                      orderModel.get('number') || '',
                      orderItemCollection.toJSON(),
                    );
                    log.success();
                  } catch (e) {
                    log.error(e.message);
                  }
                })();

                // resolve the deffered, so user does not have to wait for the print to finish
                def.resolve();
              } catch (e) {
                def.reject(e);
              }
            } else {
              // nothing to print
              def.resolve();
            }
          },
          def.reject,
        );
      return def;
    },

    processDelivery(orderModel, processingView) {
      const log = processingView.shipping();
      const orderItemCollection = new DeepModelCollection(orderModel.get('order_items'));
      let hasPartialDelivery = false;
      // Looping over the orderItems from the model to create order items to ship
      const shippingOrderItems = [];
      orderItemCollection.each((orderItemModel) => {
        const quantity = parseInt(orderItemModel.get('quantity'), 10);
        const shipmentOrderItem = {
          id: orderItemModel.get('id'),
          quantity,
        };

        // Getting the orderItem from the currentCurrentOrderItemCollection.
        const currentOrderItemModel = CurrentOrderItemCollection.get(orderItemModel.get('extra.frontend_id'));
        if (currentOrderItemModel) {
          // Update quantity with the to-be-shipped quantity
          const toBeShippedQuantity = parseInt(currentOrderItemModel.get('to_be_shipped_quantity'), 10);
          shipmentOrderItem.quantity = _.isNaN(toBeShippedQuantity)
            ? quantity
            : toBeShippedQuantity;

          const serialNos = currentOrderItemModel.get('serial_nos') || [];
          // Check the still to be shipped quantity, this is needed for printing.
          if (toBeShippedQuantity !== quantity) {
            orderItemModel.set('still_to_be_shipped_quantity', quantity - toBeShippedQuantity);
            hasPartialDelivery = true;
            shipmentOrderItem.serial_nos = currentOrderItemModel.get('to_be_shipped_serial_nos');
          } else if (serialNos.length) {
            // full shipping add the serials if there
            shipmentOrderItem.serial_nos = serialNos;
          }
        }

        if (shipmentOrderItem.quantity !== 0) {
          shippingOrderItems.push(shipmentOrderItem);
        }
      });
      // Update the orderModel with the order_items with their serials. (For printing the receipt)
      orderModel.unset('order_items');
      orderModel.set('order_items', orderItemCollection.toJSON());

      const shippedParams = {
        id: orderModel.get('id'),
        fields: { order_items: shippingOrderItems },
      };

      log.setProcessingStatus();
      let def;
      if (hasPartialDelivery) {
        if (shippingOrderItems.length > 0) {
          def = this.scheduleDeliveryBackgroundTask({
            orderId: orderModel.get('id'),
            shippingMethodId: orderModel.get('shipping_method_id'),
            shippingOrderItems,
          });
        } else {
          def = (new $.Deferred()).resolve(); // nothing was selected
        }
      } else {
        def = this.markAsShippedJob(shippedParams);
      }
      log.def(def);
      return def;
    },

    async markAsShippedJob(shippedParams) {
      return BGSync.addJob(
        this.JOB_MARK_SHIPPED,
        [this.getOrderBackRef(shippedParams.id)],
        {
          shippedParams,
        },
      );
    },

    markAsShipped(args) {
      const model = new OrderModel();
      return BGSync.awaitDeferred(model.markAsShipped(args.shippedParams));
    },

    scheduleDeliveryBackgroundTask({ orderId, shippingMethodId, shippingOrderItems }) {
      return BGSync.addJob(
        this.JOB_MARK_PARTIAL_SHIPPED,
        [this.getOrderBackRef(orderId)],
        {
          orderId,
          shippingMethodId,
          shippingOrderItems,
        },
      );
    },

    async executeDeliveryBackgroundTask({ orderId, shippingMethodId, shippingOrderItems }) {
      if (shippingOrderItems.length === 0) {
        return; // nothing to process
      }
      let shipmentId;
      let deliveredStatusTypeId;
      try {
        // Create shipment and delivered status type id
        [shipmentId, deliveredStatusTypeId] = await Promise.all([
          this.createPickUpShipment({
            orderId,
            shippingOrderItems,
            shippingMethodId,
          }),
          this.getDeliveredStatusTypeId(),
        ]);

        // Mark shipment as delivered
        await this.updateShipment(shipmentId, deliveredStatusTypeId);
      } catch (err) {
        if (shipmentId) {
          // On error we are removing the shipment.
          this.deleteShipment(shipmentId);
        }
        throw err;
      }
    },

    createPickUpShipment({ orderId, shippingOrderItems, shippingMethodId }) {
      return new Promise((resolve, reject) => {
        const model = new OrderShipmentModel({
          order_id: orderId,
          order_items: shippingOrderItems,
          shipping_method_id: shippingMethodId,
          force_negative_stock_if_missing: true,
        });

        model.newPickUpInStore().then(resolve, reject);
      });
    },

    deliveredStatusTypeId: null,

    getDeliveredStatusTypeId() {
      return new Promise((resolve, reject) => {
        if (this.deliveredStatusTypeId) {
          resolve(this.deliveredStatusTypeId);
        } else {
          const collection = new ShipmentStatusTypeCollection();
          const params = {
            start: 0,
            limit: 1,
            filters: [
              {
                name: 'alias__=',
                val: 'Delivered',
              },
              {
                name: 'module_name__=',
                val: 'ShippingModule',
              },
            ],
          };

          collection.fetch({ params })
            .then(() => {
              const model = collection.first();
              if (model) {
                this.deliveredStatusTypeId = model.get('id');
                resolve(this.deliveredStatusTypeId);
              } else {
                reject(new Error('No shipment delivery status type found'));
              }
            }, reject);
        }
      });
    },

    updateShipment(shipmentId, deliveredStatusTypeId) {
      return new Promise((resolve, reject) => {
        const model = new ShipmentModel({
          notify: false,
          shipment_status_type_id: deliveredStatusTypeId,
          id: shipmentId,
        });

        model.save()
          .then(resolve, reject);
      });
    },

    deleteShipment(shipmentId) {
      const model = new ShipmentModel({
        id: shipmentId,
      });

      model.destroy()
        .fail((resp) => Toaster.error(resp.error));
    },

    /**
     * @deprecated
     * @param args
     * @return {Promise | Promise<unknown>}
     */
    attachPaymentIds(args) {
      return BGSync.awaitDeferred(Upx.call(
        'ShopModule', 'attachPaymentIdsToOrder', {
          fields: { payment_ids: args.paymentIds },
          id: args.orderId,
        },
      ));
    },

    createMembership(orderModel, processingView) {
      const cards = [];

      CurrentOrderItemCollection.each((model) => {
        const type = model.get('type');
        if (
          type === CurrentOrderItemCollection.TYPE_MEMBERCARD
          || type === CurrentOrderItemCollection.TYPE_MEMBERCARD_GIFTCARD
        ) {
          const code = model.get('code');
          const today = new Moment();
          const nextYear = today.add(1, 'years');

          // Get relation from selected customer model if not set in model
          let relationDataId = SelectedCustomerModel.get('id');
          if (model.has('relation_data_id')) {
            relationDataId = model.get('relation_data_id');
          }
          // set model
          const creationModel = new MembercardModel({
            relation_data_id: relationDataId,
            code,
            active: true,
            due_date_inclusive: nextYear.format(),
            notes: model.get('summary'),
          });
          cards.push({
            card: creationModel,
            code,
          });
        }
      });

      const def = new $.Deferred();
      if (cards.length > 0) {
        const calls = [];
        const failed = [];

        for (let i = 0; i < cards.length; i++) {
          const card = cards[i];
          const log = processingView.membercard(card);
          calls.push(Upx.prepareCall(
            () => {
              log.setProcessingStatus();
              return card.card.setMemberCard();
            },
            () => {
              // as extra log, we don`t catch the error on it
              this.newOrderLogComment(
                orderModel.get('id'),
                Locale.translate('created_customer_card'),
                Locale.translate('created_customer_card_no_{0}', card.code),
              );
              log.success();
            },
            // on error
            (error) => {
              failed.push(card.code);
              console.error('Failed to create customer card', error);
              CashRegisterApi.logAction('MEMBER_CARD_ERROR', {
                code: card.code,
                error,
              });
              log.error(error);
            },
          ));
        }

        Upx.eachCall(calls, false)
          .then(
            () => {
              if (failed.length) {
                def.reject({
                  error: Locale.translate('failed_customer_cards_s_{0}', failed.join(', ')),
                });
              } else {
                def.resolve();
              }
            },
            def.reject,
          );
      } else {
        def.resolve();
      }
      return def.promise();
    },

    createGiftcards(orderModel, processingView) {
      const cards = [];

      const orderItemIds = {};
      const items = orderModel.get('order_items') || [];
      items.forEach((item) => {
        if (item.extra && item.extra.frontend_id) {
          const frID = item.extra.frontend_id;
          orderItemIds[frID] = item.id;
        }
      });

      CurrentOrderItemCollection.each((model) => {
        const type = model.get('type');
        if (
          type === CurrentOrderItemCollection.TYPE_GIFTCARD
          || type === CurrentOrderItemCollection.TYPE_TOP_UP
          || type === CurrentOrderItemCollection.TYPE_MEMBERCARD_GIFTCARD
        ) {
          const frID = model.get('id');
          const code = model.get('code');
          if (!(frID in orderItemIds)) {
            throw new Error(`Cannot find frontend id for private giftcard ${code}`);
          }
          const balance = model.get('ppu_wt');
          const creationModel = new GiftCardOnOrderItemModel({
            gift_card_code: code,
            order_item_id: orderItemIds[frID],
            payment_provider_id: GiftCardPaymentProvider.get('id'),
            balance_change_value: balance,
            balance_change_discount_value: model.getDiscountPpuWt(),
          });
          if (model.get('relation_data_id')) {
            creationModel.set('relation_data_id', model.get('relation_data_id'));
          }
          cards.push({
            card: creationModel,
            code,
            balance,
          });
        }
      });

      const def = new $.Deferred();
      if (cards.length > 0) {
        const calls = [];
        const failed = [];

        for (let i = 0; i < cards.length; i++) {
          const card = cards[i];
          const log = processingView.giftcard(card);
          calls.push(Upx.prepareCall(
            () => {
              log.setProcessingStatus();
              return card.card.setGiftCardOnOrderItem();
            },
            () => {
              // as extra log, we don`t catch the error on it
              this.newOrderLogComment(
                orderModel.get('id'),
                Locale.translate('created_gift_card'),
                Locale.translate('created_gift_card_no_{0}_balance_{1}',
                  [
                    card.code,
                    Currency.format(
                      this.getCurrencyIso(),
                      card.balance,
                    ),
                  ]),
              );
              log.success();
            },
            // on error
            (error) => {
              failed.push(card.code);
              console.error('Failed to create gift card', error);
              CashRegisterApi.logAction('MEMBER_CARD_ERROR', {
                code: card.code,
                error,
              });
              log.error(error);
            },
          ));
        }

        Upx.eachCall(calls, false)
          .then(
            () => {
              if (failed.length) {
                def.reject({
                  error: Locale.translate('failed_gift_card_s_{0}', failed.join(', ')),
                });
              } else {
                def.resolve();
              }
            },
            def.reject,
          );
      } else {
        def.resolve();
      }
      return def.promise();
    },

    getOrderItemsData(additionalFields = []) {
      const order_items = [];
      const order_fields = [
        ...[
          'ppu_wt',
          'before_discount_ppu_wt',
          'quantity',
          'sku',
          'name',
          'description',
          'shop_product_id',
          'attribute_option_ids',
          'shipping_method_id',
          'payment_provider_method_id',
          'is_shipping',
          'is_payment',
          'serial_nos',
        ],
        ...additionalFields,
      ];

      const sub_order_fields = [
        'ppu_wt',
        'quantity',
        'sku',
        'name',
        'shop_product_id',
        'extra',
      ];
      CurrentOrderItemCollection.each((model) => {
        const orderItem = {
          extra: {
            frontend_id: model.get('id'), // Needed to later later check which item belongs to which
            discount_ppu_wt: parseFloat(model.get('discount_ppu_wt')),
          },
        };

        // Check if there is a length set so we save it when needed
        const length = parseFloat(model.get('length'));
        if (length > 0) {
          // The before_discount_after_length_ppu_wt is the ppu_wt after the length was applied, but before the discount was applied
          orderItem.extra.before_discount_after_length_ppu_wt = parseFloat(model.get('ppu_wt'));
        }

        order_fields.forEach((field) => {
          let value;
          if (field === 'shop_product_id') {
            if (model.has(field)) {
              value = model.get(field);
            }
          } else if (field === 'ppu_wt') {
            const ppu = model.get('ppu_wt');
            let discount = model.get('discount_ppu_wt');
            if (!discount) {
              discount = '0.00';
            }
            value = Currency.Math.subtract(
              Currency.toCurrency(ppu),
              Currency.toCurrency(discount),
            );
          } else if (model.has(field)) {
            value = model.get(field);
          }
          if (!_.isUndefined(value)) {
            orderItem[field] = value;
          }
        });

        if (model.has('sub_items')) {
          const subItems = [];
          const availableSubItem = model.getCalculatedSubItems();
          availableSubItem.forEach((availableItem) => {
            const newItem = {};
            sub_order_fields.forEach((field) => {
              if (field in availableItem) {
                newItem[field] = availableItem[field];
              }
            });
            subItems.push(newItem);
          });
          orderItem.subitems = subItems;
        }
        order_items.push(orderItem);
      });
      return order_items;
    },

    getCurrencyIso() {
      return DefaultShopConfigurationModel.getCurrencyIso3();
    },

    retryCallBackForInvalidOrderProducts(error, orderModel) {
      const invalid_shop_product_ids = error.details.shop_product_ids;
      orderModel.unset('order_items');
      let order_items = this.getOrderItemsData(['tax_rate_id']);

      order_items = order_items.map((item) => {
        if (invalid_shop_product_ids.includes(item.shop_product_id)) {
          delete item.shop_product_id;
        } else {
          delete item.tax_rate_id;
        }

        return item;
      });

      orderModel.set({ order_items });
    },

    createInitialOrder(outputOrderModel = null, extraModelAttributes = {}) {
      const order_items = this.getOrderItemsData();
      const orderModel = new OrderModel({
        ...{
          force_backorder_if_not_on_stock: true,
          force_order_if_product_not_active: true,
          currency_iso3: DefaultShopConfigurationModel.getCurrencyIso3(),
          item_no: order_items.length,
          calculate_from_wt: true,
          order_items,
          allow_negative_product_qty: true,
          device_id: DeviceConfig.getDeviceId(),
          shop_id: PointOfSaleSetting.get('id'),
        },
        ...extraModelAttributes,
      });

      if (SelectedCustomerModel.has('id')) {
        orderModel.set('relation_data_id', SelectedCustomerModel.get('id'));
      } else {
        orderModel.set('is_anonymous', true);
      }

      CashRegisterApi.logAction('INITIAL_ORDER_CREATE', {
        order: orderModel.toJSON(),
      });
      const def = new $.Deferred();
      Upx.callWithRetry(
        () => orderModel.save(),
        3,
        (error) => {
          if (error.class === this.ERROR_INVALID_ORDER_PRODUCTS) {
            this.retryCallBackForInvalidOrderProducts(error, orderModel);
          }
        },
      )
        .then(
          (order_data) => {
            outputOrderModel = outputOrderModel || new OrderModel();
            outputOrderModel.set(order_data);
            def.resolve(outputOrderModel);

            // if new order is created we need to cancel all the old ones
            // which ware not cancelled for some reason
            // only one order can be "active" on POS at single moment
            LastOrdersCollection.cancelAllUnprocessedOrders();

            // save the current order
            LastOrdersCollection.saveOrder(order_data.id);
          },
          def.reject,
        );

      def.then(() => {
        CashRegisterApi.logAction('INITIAL_ORDER_CREATE_SUCCESS', {
          order: orderModel.toJSON(),
          order_id: orderModel.get('id'),
        });
      }, (err) => {
        CashRegisterApi.logAction('INITIAL_ORDER_CREATE_ERROR', {
          order: orderModel.toJSON(),
          err,
        });
      });
      return def.promise();
    },

    __translations_neverCalled() {
      // [0] For translation sake
      Locale.translate('open');
      Locale.translate('paid');
      Locale.translate('expired');
      Locale.translate('error');
      Locale.translate('cancelled');
      Locale.translate('refunded');
      Locale.translate('verify');
      Locale.translate('paidout');
    },

    getPreviousOrder() {
      return LastOrdersCollection.getLastSuccessOrder();
    },

  });
  return new OrderComponent();
});
