define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/models/connection',
  'modules/shop.cash-register-retail/templates/overlays/noInternet',
], (
  $, _, Backbone,
  CashRegisterApi, ConnectionModel, NoInternetTemplate,
) => ({

  isShown: false,

  connectionModel: null,

  showOverlay(connectionModel) {
    if (location.hash !== '#customer-screen') {
      this.isShown = true;
      CashRegisterApi.logAction('NO_INTERNET_SHOW');

      this.connectionModel = connectionModel;
      const $overlay = this.getOverlay();
      const $page = $('#main');
      $page.prepend($overlay);
      if (this.connectionModel && 'on' in this.connectionModel) {
        this.connectionModel.on('change', this.getOverlay, this);
      }
    }
  },

  hideOverlay() {
    if (this.isShown) {
      this.isShown = false;
      CashRegisterApi.logAction('NO_INTERNET_HIDE');
      const $overlay = this.getOverlay();
      $overlay.remove();
      if (this.connectionModel && 'off' in this.connectionModel) {
        this.connectionModel.off('change', this.getOverlay, this);
      }
      this.connectionModel = null;
    }
  },

  getOverlay() {
    let $overlay = $('div[data-no-internet]');
    if (!$overlay.get(0)) {
      $overlay = $('<div>')
        .attr('data-no-internet', 'no-internet')
        .attr('id', 'no-internet');
    }

    $overlay.html(
      NoInternetTemplate(ConnectionModel.toJSON()),
    );

    this.setOverlayEvents($overlay);

    return $overlay;
  },

  setOverlayEvents($overlay) {
    const $settings = $overlay.find('[data-action=settings]');
    $settings.off('click');
    $settings.on('click', () => {
      CashRegisterApi.call('/settings/network', 'get');
    });

    const $retry = $overlay.find('[data-action=retry]');
    $retry.off('click');
    $retry.on('click', () => {
      ConnectionModel.set({
        internet: null,
        backend: null,
      });
      // Timeout is here so it looks like it doing something,
      // Else if there is no internet connection, it will go to quick. and looks broken
      setTimeout(() => {
        ConnectionModel.checkConnection();
      }, 1000);
    });
  },

}));
