define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/views/popups/orderShippingPopup',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/views/shippingModal/handoutPopup',
  'modules/shop.cash-register-retail/views/shippingModal/deliverPopup',

  'upx.modules/ShippingModule/collections/ShippingMethod',
  'modules/shop.common/collections/upx/ProviderMethodType',

  'modules/shop.cash-register-retail/collections/upx/ShipmentStatusType.js',
], (
  $, _, Backbone,
  OrderShippingPopup, MessagePopup, HandoutPopup, DeliverPopup,
  ShippingMethodCollection, PaymentProviderMethodTypeCollection,
  ShipmentStatusTypeCollection,
) => ({

  loadPickupAtStoreShippingMethodCollection() {
    const def = new $.Deferred();
    if (!this.shippingMethodCollection) {
      const collection = new ShippingMethodCollection();
      const params = {
        start: 0,
        limit: 100,
        filters: [
          {
            name: 'shipping_type/module_name__=',
            val: 'ShippingModule',
          },
          {
            name: 'shipping_type/alias__=',
            val: 'PickupAtStore',
          },
        ],
      };
      collection.fetchAll({ params }).then(
        () => {
          this.shippingMethodCollection = collection;
          def.resolve({ collection });
        },
        def.reject,
      );
    } else {
      def.resolve({
        collection: this.shippingMethodCollection,
      });
    }
    return def.promise();
  },

  showError(error) {
    const view = new MessagePopup();
    view.open(error);
  },

  pickupPopup({ model }) {
    const def = new $.Deferred();
    const reject = (response) => {
      this.showError(response);
      def.reject(response);
    };
    $.when(
      this.loadPickupAtStoreShippingMethodCollection(),
      model.loadOrderItems(),
      ShipmentStatusTypeCollection.load(),
    ).then(
      ({ collection: shippingMethodCollection }) => {
        if (model.get('is_shipped')) {
          // everything is shipped,
          // check how many shipments are in the waiting for customer
          const waitingTypeId = ShipmentStatusTypeCollection.getWaitingForCustomerPickupId();
          model.loadShipmentItems().then(
            () => {
              const collection = model.getShipmentItemCollection();
              const withType = collection.filter((m) => m.get('shipment_status_type_id') === waitingTypeId);
              if (withType.length === 1) {
                // there is only on waiting lets show it
                const view = new DeliverPopup({
                  model,
                  shipmentId: withType[0].get('id'),
                });
                view.open();
                def.resolve({ view });
              } else {
                // there are many, lets just show details
                $.when(
                  model.loadPaymentItems(),
                  PaymentProviderMethodTypeCollection.load(),
                ).then(
                  () => {
                    const view = new OrderShippingPopup({
                      model,
                    });
                    view.open();
                    view.on('order:pickup', () => this.pickupPopup({ model }));
                    view.on('shipment:deliver', (shipmentId) => this.deliverPopup({ model, shipmentId }));
                    def.resolve({ view });
                  },
                  reject,
                );
              }
            },
            reject,
          );
        } else {
          $.when(
            model.loadShippedOrderItemSerials(),
          ).then(
            () => {
              const view = new HandoutPopup({
                model,
                shippingMethodCollection,
              });
              view.open();
              def.resolve({ view });
            },
            reject,
          );
        }
      },
      reject,
    );

    return def.promise();
  },

  deliverPopup({ model, shipmentId }) {
    const view = new DeliverPopup({
      model,
      shipmentId,
    });
    view.open();
  },

  detailsPopup({ model }) {
    const def = new $.Deferred();
    const reject = (response) => {
      this.showError(response);
      def.reject(response);
    };

    $.when(
      model.loadOrderItems(),
      model.loadPaymentItems(),
      PaymentProviderMethodTypeCollection.load(),
    ).then(
      () => {
        model.loadShipmentItems().then(
          () => {
            const view = new OrderShippingPopup({
              model,
            });
            view.open();
            view.on('order:pickup', () => this.pickupPopup({ model }));
            view.on('shipment:deliver', (shipmentId) => this.deliverPopup({ model, shipmentId }));
            def.resolve({ view });
          },
          reject,
        );
      },
      reject,
    );
    return def.promise();
  },

}));
